import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './shared/services/AuthGuard';
import { environment } from 'src/environments/environment';

// Activate authentication if it is deployed in production
const canActivate = environment.production ? [ AuthGuard ] : [];

const routes: Routes = [
  {
      path: '',
      loadChildren: () => import('./layout/layout.module').then(mod => mod.LayoutModule),
      canActivate
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
